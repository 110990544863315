/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Pagination from "react-paginating";
import Modal from "react-modal";
import { IoMdArrowBack } from "react-icons/io";
import Img from "gatsby-image";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

// importing custom components
import ProductListItem from "../components/ProductListItem";
import CustomHeader from "../components/CustomHeader";
import CustomFooter from "../components/CustomFooter";
import Following from "../containers/FollowContainer";
import CategorySelector from "../components/CategorySelector";

// importing grid system
import {
  Container,
  Row,
  Col,
  ScreenClassRender
} from "react-grid-system";

// importing graphql
import { graphql } from "gatsby";


// importing Fonts
import "../../static/fonts/stylesheet.css"

// global style
import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${props => (props.hidden ? "hidden" : "scroll")};
  }
`;

const Hmax = styled.div`
    font-family: Bebas Neue;
    font-size: 54px;
    margin-top: 16px;
    text-align: center;
    @media only screen and (min-width: 376px) and (max-width: 768px) {
      font-size: 44px;
    }
  `;

const SelectorContainer = styled.div`
    margin-top: 44px;
    margin-bottom: 60px;
    align-items: center;
  `;

const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 140px;
    @media only screen and (max-width: 768px) {
      justify-content: center;
      margin-top: 100px;
    }
  `;

const ValueText = styled.div`
    height: 50px;
    border: solid 1px #000000;
    background-color: #ffffff;
    font-family: "PT Mono";
    font-size: 14px;
    line-height: 3.6;
    color: #333333;
    padding-left: 25px;
  `;

const OrderButtonWrapper = styled.div`
    width: 100px;
    height: 50px;
    border: solid 1px #000000;
    background-color: #f7b4cc;
  `;

const OrderButtonText = styled.div`
    text-align: center;
    font-family: Bebas Neue;
    font-size: 16px;
    cursor: pointer;
    letter-spacing: 1.14px;
    margin-top: 16px;
  `;

const Tmax = styled.div`
    font-family: Bebas Neue;
    font-size: 28px;
  `;

const Tmid = styled.div`
    font-family: Bebas Neue;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 1px;
  `;

const Tmin = styled.div`
    font-family: "PT Mono";
    font-size: 14px;
    line-height: 1.57;
  `;

const SplitterLine = styled.div`
    background-color: black;
    width: 460px;
    height: 1.5px;
  `;

const PhoneNumber = styled.input`
    height: 46px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Bebas Neue;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.44px;
  `;

const Email = styled.input`
    height: 46px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Bebas Neue;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.5px;
  `;

const Message = styled.textarea`
    height: 60px;
    padding: 20px;
    font-family: Bebas Neue;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.5px;
  `;

const SendButtonWrapper = styled.div`
    height: 50px;
    border: solid 1px #000000;
    background-color: #f7b4cc;
  `;

const SendButtonText = styled.div`
    text-align: center;
    font-family: Bebas Neue;
    font-size: 16px;
    cursor: pointer;
    letter-spacing: 1.14px;
    margin-top: 16px;
  `;

const ReturnButtonWrapper = styled.div`
    width: 160px;
    height: 50px;
    border: solid 1px #000000;
    background-color: #f7b4cc;
  `;

const ReturnButtonText = styled.div`
    text-align: center;
    font-family: Bebas Neue;
    font-size: 16px;
    cursor: pointer;
    letter-spacing: 1.14px;
    margin-top: 16px;
  `;



const activeBtnStyle = {
  width: 44,
  height: 44,
  borderWidth: 1,
  borderColor: 'black',
  textAlign: "center",
  textDecoration: "none",
  border: "solid 1px #000000",
  backgroundColor: "white",
  fontFamily: "Bebas Neue",
  fontSize: 16
};

const arrowBtnStyle = {
  width: 44,
  height: 44,
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "white",
  fontFamily: "Bebas Neue",
  color: "#1b1b1b",
  fontSize: 16,
  border: "solid 2px #f7b4cc"
};

const AllProductPage = ({ data }) => {
  const allProducts = data.allContentfulProduct.nodes;
  const limit = 8;
  const pageCount = 5;
  const total = allProducts.length;

  const [currentPage, setCurrentPage] = useState(1);
  const [isInfoPaneOpen, setIsInfoPaneOpen] = useState(false);
  const [isOrderPaneOpen, setIsOrderPaneOpen] = useState(false);
  const [isThankPaneOpen, setIsThankPaneOpen] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  useEffect(() => {
    Modal.setAppElement("#allproducts");
  }, []);

  const handlePageChange = (page, e) => {
    setCurrentPage(page);
  };

  const defaultBtnStyle = {
    width: 44,
    height: 44,
    border: "none",
    textAlign: "center",
    textDecoration: "none",
    backgroundColor: "white",
    fontFamily: "Bebas Neue",
    fontSize: 16,
    cursor: "pointer",
  };

  return (
    <ScreenClassRender
      render={screenClass => (
        <div id="allproducts">
          <GlobalStyle
            hidden={isInfoPaneOpen || isOrderPaneOpen || isThankPaneOpen}
          />
          <CustomHeader />
          <Container style={{ marginTop: 105 }}>
            <Row>
              <Col lg={12} sm={12} md={12} xl={12}>
                <Hmax>All Products</Hmax>
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectorContainer>
                  <CategorySelector />
                </SelectorContainer>
              </Col>
            </Row>
            <Row>
              {allProducts.map((item, index) => {
                if (
                  index >= limit * (currentPage - 1) &&
                  index < limit * currentPage
                ) {
                  return (
                    <Col
                      lg={4}
                      xl={3}
                      md={6}
                      sm={12}
                      key={item.id}
                      onClick={() => {
                        setSelectedProductIndex(index);
                        setIsInfoPaneOpen(true);
                      }}
                    >
                      <ProductListItem data={item} />
                    </Col>
                  );
                } else {
                  return null;
                }
              })}
            </Row>
            <Row>
              <Col>
                <Pagination
                  total={total}
                  limit={limit}
                  pageCount={pageCount}
                  currentPage={currentPage}
                >
                  {({
                    pages,
                    currentPage,
                    hasNextPage,
                    hasPreviousPage,
                    previousPage,
                    nextPage,
                    totalPages,
                    getPageItemProps
                  }) => (
                      <PaginationContainer>
                        {hasPreviousPage && (
                          <button
                            {...getPageItemProps({
                              pageValue: previousPage,
                              onPageChange: handlePageChange,
                              style: arrowBtnStyle
                            })}
                          >
                            {"<"}
                          </button>
                        )}

                        {pages.map(page => {
                          let activePage = defaultBtnStyle;
                          if (currentPage === page) {
                            activePage = activeBtnStyle;
                          }
                          return (
                            <button
                              {...getPageItemProps({
                                pageValue: page,
                                key: page,
                                style: activePage,
                                onPageChange: handlePageChange
                              })}
                            >
                              {page}
                            </button>
                          );
                        })}

                        {hasNextPage && (
                          <button
                            {...getPageItemProps({
                              pageValue: nextPage,
                              onPageChange: handlePageChange,
                              style: arrowBtnStyle
                            })}
                          >
                            {">"}
                          </button>
                        )}
                      </PaginationContainer>
                    )}
                </Pagination>
              </Col>
            </Row>
          </Container>
          <Following />
          <CustomFooter />

          <SlidingPane
            closeIcon={
              <IoMdArrowBack
                style={{ marginLeft: "-20px", width: "30px", height: "30px" }}
              />
            }
            isOpen={isInfoPaneOpen}
            from="right"
            width={["lg", "xl", "md"].includes(screenClass) ? "460px" : "375px"}
            onRequestClose={() => setIsInfoPaneOpen(false)}
          >
            <Container
              fluid
              style={{
                backgroundColor: "#FFFFFF",
                width: ["lg", "xl", "md"].includes(screenClass)
                  ? "460px"
                  : "375px",
                height: "100%",
                marginTop: "-24px",
                marginLeft: "-32px",
                marginRight: "-24px"
              }}
            >
              <Row justify="center" align="center" style={{ padding: 0 }}>
                <Col style={{ padding: 0 }}>
                  <Img
                    style={{
                      width: ["lg", "xl", "md"].includes(screenClass)
                        ? "460px"
                        : "375px",
                      height: ["lg", "xl", "md"].includes(screenClass)
                        ? "580px"
                        : "470px"
                    }}
                    fluid={allProducts[selectedProductIndex].image.fluid}
                    imgStyle={{
                      objectFit: "unset"
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ padding: 0 }}>
                <Col style={{ padding: 0 }}>
                  <ValueText
                    style={{
                      width: ["lg", "xl", "md"].includes(screenClass)
                        ? "312px"
                        : "227px"
                    }}
                  >
                    ${allProducts[selectedProductIndex].price}
                  </ValueText>
                </Col>
                <Col style={{ padding: 0 }}>
                  <OrderButtonWrapper>
                    <OrderButtonText
                      onClick={() => {
                        setIsInfoPaneOpen(false);
                        setIsOrderPaneOpen(true);
                      }}
                    >
                      Order
                    </OrderButtonText>
                  </OrderButtonWrapper>
                </Col>
              </Row>
              <Row style={{ padding: "26px" }}>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmax>{allProducts[selectedProductIndex].title}</Tmax>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmin>USA</Tmin>
                </Col>
              </Row>
              <Row>
                <SplitterLine />
              </Row>
              <Row style={{ padding: "26px" }}>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmid>Type:</Tmid>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmin>Öl, Ale,Golden/Blond ale</Tmin>
                </Col>
                <Col
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  style={{ padding: 0, marginTop: "16px" }}
                >
                  <Tmid>Brewery:</Tmid>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmin>{allProducts[selectedProductIndex].brewery}</Tmin>
                </Col>
                <Col
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  style={{ padding: 0, marginTop: "16px" }}
                >
                  <Row>
                    <Col>
                      <Tmid>Alkohol:</Tmid>
                    </Col>
                    <Col>
                      <Tmid>Volym:</Tmid>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Row>
                    <Col>
                      <Tmin>
                        {allProducts[selectedProductIndex].alcohol_percentage}%
                        by vol
                      </Tmin>
                    </Col>
                    <Col>
                      <Tmin>335ml</Tmin>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <SplitterLine />
              </Row>
              <Row style={{ padding: "26px" }}>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmid>Product Description:</Tmid>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmin>
                    Big Wave is a lighter-bodied golden ale with a tropical hop
                    aroma and flavor—smooth, easy drinking, and refreshing. The
                    use of caramel malt contributes to the golden hue of this
                    beer, and our special blend of hops provides a bright,
                    quenching finish that makes it a struggle to not grab
                    another one.
                  </Tmin>
                </Col>
              </Row>
            </Container>
          </SlidingPane>

          <SlidingPane
            closeIcon={
              <IoMdArrowBack
                style={{ marginLeft: "-20px", width: "30px", height: "30px" }}
              />
            }
            isOpen={isOrderPaneOpen}
            from="right"
            width={["lg", "xl", "md"].includes(screenClass) ? "460px" : "375px"}
            onRequestClose={() => {
              setIsOrderPaneOpen(false);
              setIsInfoPaneOpen(true);
            }}
          >
            <Container
              fluid
              style={{
                backgroundColor: "#FFFFFF",
                width: ["lg", "xl", "md"].includes(screenClass)
                  ? "460px"
                  : "375px",
                height: "90%",
                marginTop: "-24px",
                marginLeft: "-32px",
                marginRight: "-24px"
              }}
            >
              <Row
                style={{
                  padding: "40px",
                  marginTop: ["lg", "xl", "md"].includes(screenClass)
                    ? "120px"
                    : "0px"
                }}
              >
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmax>
                    Leave your{" "}
                    <Tmax style={{ color: "#F7B4CC", display: "inline-block" }}>
                      phone number
                    </Tmax>{" "}
                    or email address to order the product. We will contact you
                    as soon as possible.
                  </Tmax>
                </Col>
              </Row>
              <Row style={{ padding: "0 40px 0 40px" }}>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Tmid>Phone Number</Tmid>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <PhoneNumber
                    style={{
                      width: ["lg", "xl", "md"].includes(screenClass)
                        ? "340px"
                        : "250px"
                    }}
                    placeholder="+4_"
                  />
                </Col>
              </Row>
              <Row style={{ padding: "0 40px 0 40px" }}>
                <Col
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  style={{ padding: 0, marginTop: "16px" }}
                >
                  <Tmid>Email Address</Tmid>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Email
                    style={{
                      width: ["lg", "xl", "md"].includes(screenClass)
                        ? "340px"
                        : "250px"
                    }}
                    placeholder="Email Address"
                  />
                </Col>
              </Row>
              <Row style={{ padding: "0 40px 0 40px" }}>
                <Col
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  style={{ padding: 0, marginTop: "16px" }}
                >
                  <Tmid>Message</Tmid>
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} style={{ padding: 0 }}>
                  <Message
                    style={{
                      width: ["lg", "xl", "md"].includes(screenClass)
                        ? "340px"
                        : "250px"
                    }}
                    placeholder="Type your message"
                  />
                </Col>
              </Row>
              <Row style={{ padding: "0 40px 0 40px" }}>
                <Col
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  style={{ padding: 0, marginTop: "30px" }}
                >
                  <SendButtonWrapper
                    style={{
                      width: ["lg", "xl", "md"].includes(screenClass)
                        ? "380px"
                        : "290px"
                    }}
                  >
                    <SendButtonText
                      onClick={() => {
                        setIsOrderPaneOpen(false);
                        setIsThankPaneOpen(true);
                      }}
                    >
                      Send Request
                    </SendButtonText>
                  </SendButtonWrapper>
                </Col>
              </Row>
            </Container>
          </SlidingPane>

          <SlidingPane
            closeIcon={
              <IoMdArrowBack
                style={{ marginLeft: "-20px", width: "30px", height: "30px" }}
              />
            }
            isOpen={isThankPaneOpen}
            from="right"
            width={["lg", "xl", "md"].includes(screenClass) ? "460px" : "375px"}
            onRequestClose={() => {
              setIsThankPaneOpen(false);
              setIsOrderPaneOpen(true);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <img
                alt=""
                srcSet="/images/logo1@2x.png 2x, /images/logo1@3x.png 3x"
                src="/images/logo1@3x.png"
                style={{
                  marginTop: ["lg", "xl", "md"].includes(screenClass)
                    ? "290px"
                    : "100px",
                  width: "77px",
                  height: "77px"
                }}
              />
              <Tmax style={{ marginTop: "32px" }}>Thank you for request!</Tmax>
              <Tmax>We will contact you as soon</Tmax>
              <ReturnButtonWrapper style={{ marginTop: "50px" }}>
                <ReturnButtonText
                  onClick={() => {
                    setIsThankPaneOpen(false);
                  }}
                >
                  Return to shop
                </ReturnButtonText>
              </ReturnButtonWrapper>
            </div>
          </SlidingPane>
        </div>
      )}
    />
  );
};

export default AllProductPage;

export const query = graphql`
  query allProducts {
    allContentfulProduct(filter: { image: { id: { ne: null } } }) {
      nodes {
        id
        title
        brewery
        price
        packaging
        description{
          description
        }
        type 
        alcohol_percentage
        image {
          id
          fluid {
            ...GatsbyContentfulFluid
          }
           file {
            url
          }
        }
      }
    }
  }
`;
